import React from 'react'
import { graphql, navigate } from 'gatsby'
import styled from 'styled-components'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { breakpoints, Image } from '@ecommerce/shared'
import Layout from '../components/Layout/LayoutStaticPage'
import { PgPageProps } from '../types/PgPages'
import { Icon } from '../components/Icon/Icon'

const Wrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.medium};
  min-height: calc(100vh - 234px);
`

const Container = styled.div`
  max-width: 790px;
  margin: auto;
  background: ${({ theme }) => theme.colors.white};
  padding: 72px 90px;
  min-height: 100vh;
  position: relative;
  margin-bottom: 35px;

  h1 {
    color: ${({ theme }) => theme.colors.primary};
    margin: 30px 0;
  }

  h2 {
    font-size: 30px;
    margin: 60px 0;
    max-width: 90%;
  }

  a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
    font-weight: bold;
  }

  h3,
  h2,
  b {
    color: ${({ theme }) => theme.colors.black};
  }

  @media screen and (${breakpoints.tabletPortrait.max}) {
    padding: 45px;
    margin-bottom: 0;
  }
`

const BackButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  top: 40px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;

  svg {
    width: 100%;
    height: 100%;
  }

  @media screen and (${breakpoints.tabletPortrait.max}) {
    top: 16px;
  }
`

const InfoListWrapper = styled.div`
  .info-items {
    .info-item {
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: 30px;

      .left {
        margin-right: 25px;

        img {
          width: 70px;
          height: 70px;
          border-radius: 50%;
        }
      }

      .right {
        h5 {
          font-size: 18px;
          margin: 0;
          color: ${({ theme }) => theme.colors.black};
        }

        p {
          margin-top: 15px;
        }
      }
    }
  }
`
const BenefitsWrapper = styled.div`
  margin-top: 60px;

  h2 {
    margin-bottom: 30px;
  }

  .benefits {
    display: flex;
    flex-wrap: wrap;

    .benefit-item {
      width: 50%;
      align-self: stretch;
      padding: 0 15px;
      margin: 15px 0;

      &__inner {
        padding: 35px 30px;
        text-align: center;
        height: 100%;
        box-shadow: ${({ theme }) => theme.boxShadow.lvlThree};

        img {
          width: 60px;
          height: 60px;
        }

        h5 {
          font-size: 18px;
        }

        p {
          font-size: 17px;
        }
      }

      @media screen and (${breakpoints.tabletPortrait.max}) {
        width: 100%;
      }
    }
  }
`

const CyberPage = ({ data }: PgPageProps) => {
  const {
    title: pageTitle,
    template: { content, infoListTitle, infoList = [], benefitsList = [], benefitsListTitle },
  } = data.allContentfulPgPage.edges[0].node

  /* eslint-disable react/no-danger */
  return (
    <Layout title={pageTitle}>
      <Wrapper>
        <Container>
          <BackButton onClick={() => navigate('../')}>
            <Icon iconId="arrow_left" />
          </BackButton>
          {documentToReactComponents(content.json)}
          <InfoListWrapper>
            <h2 dangerouslySetInnerHTML={{ __html: infoListTitle }} />
            <div className="info-items">
              {infoList?.map(({ title, text, id, icon }) => (
                <div key={id} className="info-item">
                  {icon && (
                    <div className="left">
                      <Image preload={false} src={icon.file.url} alt={title} />
                    </div>
                  )}
                  <div className="right">
                    <h5>{title}</h5>
                    {text && text.json ? documentToReactComponents(text.json) : null}
                  </div>
                </div>
              ))}
            </div>
          </InfoListWrapper>
          <BenefitsWrapper>
            <h2 dangerouslySetInnerHTML={{ __html: benefitsListTitle }} />
            <div className="benefits">
              {benefitsList?.map(({ id, title, text, icon }) => (
                <div key={id} className="benefit-item">
                  <div className="benefit-item__inner">
                    {icon && <Image src={icon.file.url} alt={title} />}
                    <h5>{title}</h5>
                    {text && text.json ? documentToReactComponents(text.json) : null}
                  </div>
                </div>
              ))}
            </div>
          </BenefitsWrapper>
        </Container>
      </Wrapper>
    </Layout>
  )
}

export default CyberPage

export const query = graphql`
  query CyberQuery($cyberId: String!) {
    allContentfulPgPage(filter: { contentful_id: { eq: $cyberId } }) {
      edges {
        node {
          id
          title
          template {
            ... on ContentfulTmCyber {
              infoListTitle
              infoList {
                id
                title
                text {
                  json
                }
                icon {
                  file {
                    url
                  }
                }
              }
              benefitsListTitle
              benefitsList {
                id
                title
                text {
                  json
                }
                icon {
                  file {
                    url
                  }
                }
              }
              content {
                json
              }
            }
          }
        }
      }
    }
  }
`
